import { TDraftOpenGrantApplication } from '../../../types'
import DraftApplicationOwner from './draft-application-owner'
import DraftApplicationInfo from './draft-application-info'

function DraftApplication({
  application,
}: {
  application: TDraftOpenGrantApplication
}) {
  return (
    <div className="mb-4 rounded-md border border-gray-200 shadow-sm">
      <div className="px-6 py-7 hover:bg-gray-50">
        <div className="flex gap-10">
          <DraftApplicationOwner data={application} />
          <DraftApplicationInfo data={application} />
        </div>
      </div>
    </div>
  )
}

export default DraftApplication
