import { Pagination, Text } from '@changex/design-system'
import Layout from 'app/funds/[id]/layout'
import { ApplicationsTabs } from 'features/funds/components/applications-tabs'
import { useApplicationsQueryParams } from '@features/funds/hooks/use-applications-query-params'
import {
  APPLICATIONS_PAGE_SIZE,
  APPLICATIONS_SORT_CRITERIA,
  APPLICATIONS_TAB_LIST,
} from '@features/funds/constants'
import { useApplicationsListCount } from '@features/funds/hooks/use-applications-list-count'
import { useCallback, useEffect } from 'react'
import { useFund } from 'shared/providers'
import { useApplicationList } from '@features/funds/hooks/use-application-list'
import { sum } from 'lodash'
import { useNavigate, useParams } from 'react-router-dom'
import { ApplicationsCount } from 'features/funds/components/applications-count'
import ApplicationsList from 'features/funds/components/applications-list'
import ApplicationsActions from 'features/funds/components/applications-actions'
import ApplicationsHeader from '@features/funds/components/applications-header'

const FundApplicationsContent = () => {
  const fund = useFund()
  const totalApplications = fund.aggregates
    ? sum(Object.values(fund?.aggregates))
    : 0
  const navigate = useNavigate()

  const { applicationId } = useParams<{ applicationId: string }>()

  const [applicationsQueryParams, setApplicationsQueryParams] =
    useApplicationsQueryParams()

  useEffect(() => {
    if (fund && !applicationId && !applicationsQueryParams.tab) {
      const tabs = APPLICATIONS_TAB_LIST(fund.options.type).map(
        (tab) => tab?.key
      )
      const tabId = tabs.findIndex((tab) =>
        fund.aggregates?.hasOwnProperty(tab)
      )

      if (tabId >= 0) {
        navigate(`?tab=${tabId}`)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const applications = useApplicationList()

  useEffect(() => {
    if (applicationId && applications.data) {
      const tabs = APPLICATIONS_TAB_LIST(fund.options.type).map(
        (tab) => tab?.statusId
      )
      const tabId = tabs.findIndex(
        (tab) => tab === applications.data?.results[0].applicationStatus
      )

      navigate(`/funds/${fund.id}/applications/${applicationId}?tab=${tabId}`)
    }
  }, [applicationId, applications.data, fund.id, fund.options.type, navigate])

  const resultCount = useApplicationsListCount() ?? 0

  // TODO: improve type (needs to be updated in the design system)
  const handleFilter = useCallback(
    (filter: any) => {
      setApplicationsQueryParams({
        page: filter.page,
      })
    },
    [setApplicationsQueryParams]
  )

  if (
    !applications.isLoading &&
    !applications.isFetching &&
    totalApplications === 0
  ) {
    return (
      <div className="max-w-changex mx-auto my-20 text-center">
        <Text size="2xl">No applicants yet</Text>
        <p className="mt-4">Come back soon to see new applications.</p>
      </div>
    )
  }

  return (
    <div>
      <ApplicationsHeader totalApplications={totalApplications} />
      <ApplicationsTabs fund={fund} />
      <ApplicationsActions sortCriteria={APPLICATIONS_SORT_CRITERIA} />
      <div className="max-w-changex mx-auto">
        <ApplicationsCount
          count={applications.data?.meta?.stats?.total?.count || 0}
        />
        <ApplicationsList />
      </div>
      {resultCount > APPLICATIONS_PAGE_SIZE ? (
        <div className="mt-4">
          <Pagination
            onFiltering={handleFilter}
            currentPage={applicationsQueryParams.page ?? 1}
            ariaLabel="Applications paging"
            meta={applications.data?.meta}
            resultsPerPage={APPLICATIONS_PAGE_SIZE}
          />
        </div>
      ) : null}
    </div>
  )
}

export default function FundApplicationsPage() {
  return (
    <Layout>
      <FundApplicationsContent />
    </Layout>
  )
}
