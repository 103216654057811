import Layout from 'app/funds/[id]/layout'
import { MapFilters } from 'features/funds/components/map-filters'
import MapLegend from 'features/funds/components/map-legend/map-legend'
import { Solution } from '@types'
import { useState, useEffect, useRef } from 'react'
import GoogleMapReact from 'google-map-react'
import { useLocationMapQuery } from 'features/funds/hooks/use-location-map-query'
import {
  ClusterCircleMarker,
  Marker,
  MarkerPointer,
} from 'features/funds/components/map-markers/map-markers'
import useSuperCluster from 'use-supercluster'
import MapCard from 'features/funds/components/map-card/map-card'
import { useSolutionsQuery } from 'features/funds/hooks/use-solutions-query'
import {
  LocationMapApplication,
  LocationPointsForCluster,
  TMapFilters,
} from 'features/funds/types'
import { utils } from 'features/funds/utils'
import { MapTabs } from 'features/funds/components/map-tabs'
import { useFund } from 'shared/providers'
import { Skeleton } from '@changex/design-system'

const MAP = {
  defaultZoom: 7,
  defaultCenter: {
    lat: 53.359886623396456,
    lng: -6.328964007851827,
  },
  defaultOptions: {
    maxZoom: 20,
    styles: [],
  },
  clusterOptions: {
    radius: 60,
    maxZoom: 20,
  },
  defaultHeatmapData: {
    positions: [],
    options: {},
  },
  heatmapOptions: {
    maxZoom: 20,
    styles: [
      {
        stylers: [
          { saturation: -40 },
          { gamma: 0.2 },
          { lightness: 3 },
          { visibility: 'on' },
        ],
      },
    ],
  },
  heatmapDataOptions: {
    radius: 40,
    opacity: 0.5,
  },
}

const MapContent = () => {
  const [zoom, setZoom] = useState(MAP.defaultZoom)
  const [bounds, setBounds] = useState({})
  const [showInfoView, setShowInfoView] = useState(false)
  const [selectedMarker, setSelectedMarker] = useState('')
  const [solution, setSolution] = useState({} as Solution)
  const [isMapCardOpen, setIsMapCardOpen] = useState(false)
  const [ideaId, setIdeaId] = useState('')
  const [heatmapActive, setHeatmapActive] = useState(false)
  const [boundsExist, setBoundsExist] = useState(false)

  const mapRef = useRef<any>()
  const mapCardRef = useRef<any>()
  const [filters, setFilters] = useState<TMapFilters>({})

  const locationMapQuery: any = useLocationMapQuery(filters)
  let locationMapApplications: LocationMapApplication[] =
    locationMapQuery.data?.results

  if (!boundsExist && locationMapApplications) {
    setBoundsExist(true)
  }

  const solutionsQuery: any = useSolutionsQuery()
  const solutions: Solution[] = solutionsQuery.data?.results.solutions

  const fund = useFund()
  const fundCenter = fund.options.locationMatch?.options?.centre

  if (ideaId !== '') {
    locationMapApplications = locationMapApplications?.filter((application) => {
      return Number(application.solutionId) === Number(ideaId)
    })
  }

  const applicationsWithImpactData =
    locationMapApplications?.filter(
      (application) => Object.keys(application.impactData).length > 0
    ) ?? []

  const heatmapData = {
    positions:
      applicationsWithImpactData.map((application) => ({
        lat: application.latitude,
        lng: application.longitude,
        weight: application.impactData.beneficiaries ?? 0,
      })) ?? [],
    options: MAP.heatmapDataOptions,
  }

  let points: LocationPointsForCluster[] = locationMapApplications?.map(
    (application: LocationMapApplication) => ({
      type: 'Feature',
      properties: {
        cluster: false,
        application,
      },
      geometry: {
        type: 'Point',
        coordinates: [
          Number(application.longitude),
          Number(application.latitude),
        ],
      },
    })
  )
  points = utils.arrangeClosePointsInCircle(points)

  const { clusters, supercluster } = useSuperCluster({
    points: points ?? [],
    bounds,
    zoom,
    options: MAP.clusterOptions,
  })

  const handleFilterChange = (name: string, value: string) => {
    setFilters((oldFilters) => ({
      ...oldFilters,
      [name]: value,
    }))
  }

  const handleResetFilters = () => {
    setFilters({})
    setIdeaId('')
  }

  const handleClusterMarkerClick = (clusterId, latitude, longitude) => {
    const expansionZoom = Math.min(
      supercluster.getClusterExpansionZoom(clusterId),
      20
    )
    mapRef.current.setZoom(expansionZoom)
    mapRef.current.panTo({ lat: latitude, lng: longitude })
  }

  const onMapChildClick = (applicationId, solutionId) => {
    setShowInfoView(!showInfoView)
    setSelectedMarker(applicationId)
    const foundSolution = solutions.filter(
      (sol) => Number(sol.id) === solutionId
    )
    setSolution(foundSolution[0])
    setIsMapCardOpen(true)
  }

  useEffect(() => {
    let handler = (e) => {
      if (!mapCardRef.current?.contains(e.target)) {
        setIsMapCardOpen(false)
      }
    }
    document.addEventListener('mousedown', handler)
    return () => {
      document.removeEventListener('mousedown', handler)
    }
  })

  const fitMapBounds = (
    map: any,
    maps: any,
    locationMapApplications: LocationMapApplication[]
  ) => {
    const bounds = new maps.LatLngBounds()
    locationMapApplications?.forEach((application) => {
      bounds.extend(
        new maps.LatLng(
          Number(application.latitude),
          Number(application.longitude)
        )
      )
    })
    map.fitBounds(bounds)
  }

  return (
    <>
      <div className="bg-copper-50">
        <div className="max-w-changex mx-auto flex flex-col gap-y-4 py-2.5">
          <MapFilters
            filters={filters}
            onFilter={handleFilterChange}
            onReset={handleResetFilters}
            solutions={solutions ?? []}
            ideaId={ideaId}
            setIdeaId={setIdeaId}
            isStateFilterDisabled={heatmapActive}
          />
        </div>
      </div>
      <div className="max-w-changex mx-auto mt-6">
        <div className="flex justify-between">
          <MapTabs
            heatmapActive={heatmapActive}
            setHeatmapActive={(shouldSetActive) => {
              if (shouldSetActive) {
                handleFilterChange('state', '')
              }
              setHeatmapActive(shouldSetActive)
            }}
          />
          <MapLegend />
        </div>
        {!boundsExist ? (
          <div className="mt-6">
            <Skeleton w="full" h="43rem" />
          </div>
        ) : (
          <div className="mt-6 h-[43rem] w-full">
            {heatmapActive ? (
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
                  libraries: ['visualization'],
                }}
                defaultCenter={fundCenter ?? MAP.defaultCenter}
                defaultZoom={MAP.defaultZoom}
                options={MAP.heatmapOptions}
                heatmap={heatmapData}
              ></GoogleMapReact>
            ) : (
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
                  libraries: ['visualization'],
                }}
                defaultCenter={fundCenter ?? MAP.defaultCenter}
                defaultZoom={MAP.defaultZoom}
                options={MAP.defaultOptions}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => {
                  fitMapBounds(map, maps, locationMapApplications)
                  mapRef.current = map
                }}
                onChange={({ zoom, bounds }) => {
                  setZoom(zoom)
                  setBounds([
                    bounds.nw.lng,
                    bounds.se.lat,
                    bounds.se.lng,
                    bounds.nw.lat,
                  ])
                }}
                heatmap={MAP.defaultHeatmapData}
              >
                {clusters.map((cluster) => {
                  const [longitude, latitude] = cluster.geometry.coordinates
                  const {
                    cluster: isCluster,
                    point_count: pointCount,
                    application,
                  } = cluster.properties
                  if (isCluster) {
                    return (
                      <Marker lat={latitude} lng={longitude} key={cluster.id}>
                        <ClusterCircleMarker
                          text={pointCount}
                          size={utils.getClusterMarkerSize(pointCount)}
                          handleClick={() =>
                            handleClusterMarkerClick(
                              cluster.id,
                              latitude,
                              longitude
                            )
                          }
                          hoverCursor
                        />
                      </Marker>
                    )
                  }
                  return (
                    <Marker lat={latitude} lng={longitude} key={application.id}>
                      {selectedMarker === application.id && (
                        <div ref={mapCardRef}>
                          <MapCard
                            application={application}
                            solution={solution}
                            open={isMapCardOpen}
                            setIsOpen={setIsMapCardOpen}
                          />
                        </div>
                      )}
                      <MarkerPointer
                        openGrant={
                          application.applicationType === 'project_application'
                        }
                        onClick={() => {
                          mapRef.current.panTo({
                            lat: latitude,
                            lng: longitude,
                          })
                          onMapChildClick(
                            application.id,
                            application.solutionId
                          )
                        }}
                      />
                    </Marker>
                  )
                })}
              </GoogleMapReact>
            )}
          </div>
        )}
      </div>
    </>
  )
}

export default function MapPage() {
  return (
    <Layout>
      <MapContent />
    </Layout>
  )
}
