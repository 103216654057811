import { CurrencyIso, TEntityType } from '@changex/design-system'
import { Fund, Nullable, Solution, User } from '@types'

export type HeardAboutUsOption =
  | 'social_media'
  | 'word_of_mouth'
  | 'newspaper_tv_radio'
  | 'changex_email'
  | 'changex_newsletter'
  | 'another_org'
  | 'partner'
  | 'other'
  | 'not_sure'

export enum TFundApplicationStatus {
  pre_allocated = 'pre_allocated',
  allocated = 'allocated',
  succeeded = 'succeeded',
  approved = 'approved',
  paid_seed = 'paid_seed',
  impact = 'impact',
  paid_impact = 'paid_impact',
  failed = 'failed',
  failed_impact = 'failed_impact',
  rejected = 'rejected',
  refunded = 'refunded',
  unfunded = 'unfunded',
}

type FundApplicationBase = {
  id: number | string
  stage: string
  applicationStatus: TFundApplicationStatus
  message: string
  teamNotes?: string
  changexNotes?: string
  createdAt: Date
  submittedAt: Date
  updatedAt: Date
  callStartTime: string
  receivedStarterCallAt?: string
  solution?: Partial<Solution>
  location: Partial<Location>
  owner: Partial<User>
  fund: Partial<Fund>
  onboardingSteps: TStep[]
  completedOnboardingSteps?: TCompletedStep[]
  challengeDaysRemaining: number
  ownerLastSeen: string
  heardAboutUs: HeardAboutUsOption
  heardAboutUsDetail: string
  ownerLastSeenAt: string
  additionalData: Object
  introductionEmailSent?: boolean
  entityType?: TEntityType
  organisationName?: string
}

export type FundApplicationReplication = FundApplicationBase & {
  solution: Partial<Solution>
  type: 'solution_applications'
  kickOffMeetingAt: string
  score: number
  members: User[]
}

export type FundApplicationOpenGrant = FundApplicationBase & {
  type: 'project_applications'
  applicantExperience: string
  budget: number
  budgetBreakdown: string
  communityEngagement: string
  expectedBeneficiaries: string
  expectedImpact: string
  expectedScope: string
  name: string
  organisationTaxId: string
  phone: string
  secondContactEmail: string
  secondContactName: string
  secondContactPhone: string
  socialProfileUrl: string
  tagline: string
  text: string
  themes: string
  timeline: string
  requestedBudget: number
}

export type FundApplication =
  | FundApplicationReplication
  | FundApplicationOpenGrant

export type Budget = {
  balance: number
  allocationBalances: {
    seed?: number
    impact?: number
  }
}

export type SolutionBudget = {
  balance: number
}

export enum PaymentAccountAccountType {
  Stripe = 'stripe',
  Wise = 'wise',
}

export type PaymentAccount = {
  accountIdentifier: string
  accountType: PaymentAccountAccountType
  currency?: CurrencyIso
  id: number
  paymentPlatformAccountName: string
  reference?: string
  status: {
    description?: string
    enabled: Nullable<boolean>
  }
}

export enum TAllocationType {
  Impact = 'impact',
  Seed = 'seed',
  Solution = 'solution',
}

export type LocationPaymentStatus = {
  allocationType: TAllocationType
  enabled: boolean
  description?: string
}

export type LocationPaymentData = {
  budgets: {
    preAllocated: Budget
    allocated: Budget
    succeeded: Budget
    approved: Budget
    paid: Budget
  }
  paymentAccounts: PaymentAccount[]
  status: LocationPaymentStatus
}

export type SolutionPaymentData = {
  budgets: {
    preAllocated: SolutionBudget
    allocated: SolutionBudget
    succeeded: SolutionBudget
    approved: SolutionBudget
    paid: SolutionBudget
  }
  paymentAccounts: PaymentAccount[]
  status: LocationPaymentStatus
}

export type PaymentData = {
  location: LocationPaymentData
  solution?: SolutionPaymentData // Can be undefined if there is no Solution allocation for the Location
}

export type Location = {
  id: string
  name: string
  slug: string
  address: string
  payments?: PaymentData
}

export type TFilter = {
  q: string
  sort: string
}

export type TStep = {
  position: number
  id: number
  stepType: string
  completed: boolean
  level: string
}

export type TCompletedStep = {
  id: number
  completed: boolean
  locationId: number
  onboardingStepId: number
}

export type ApplicationType = 'openGrant' | 'replication'

export type Feed = {
  content: FeedContent
  createdAt: string
  entryType: FeedTypes
  id: string
  socialCategoryIds: string[]
  solutionId: string
  state: string
  type: string
}

type FeedAsset = {
  id: string
  title: string
  type: string
  url: string
}

type FeedComment = {
  body: string
  createdAt: string
  id: string
  userFirstName: string
  userId: string
  userLastName: string
}

type FeedContent = FeedContentApplication | FeedContentEvent | FeedContentPost

export type FeedContentApplication = {
  countryName: string
  locationName: string
  locationSlug: string
  motivation: string
  solutionName: string
  userFirstName: string
  userId: string
  userLastName: string
}

export type FeedContentEvent = {
  countryName: string
  eventName: string
  eventStartAt: string
  eventEndAt: string
  eventSlug: string
  locationName: string
  locationSlug: string
  userFirstName: string
  userId: string
  userLastName: string
}

export type FeedContentPost = {
  assets: FeedAsset[]
  body: string
  comments: FeedComment[]
  countryName: string
  likes: number[]
  locationName: string
  locationSlug: string
  url: string
  userFirstName: string
  userId: string
  userLastName: string
}

type FeedTypes = 'application' | 'event' | 'location_post' | 'solution_post'

export enum FundType {
  Hybrid = 'hybrid',
  Open = 'open',
  Replication = 'replication',
}

export enum UserFundRole {
  Admin = 'admin',
  Regular = 'regular',
}

export type SocialCategory = {
  id: string
  name: string
}

export type SolutionsAndCategoriesFilter = {
  solutions: Solution[]
  socialCategories: SocialCategory[]
}

export type DateRangeFilters = {
  start_date: string
  end_date: string
}

export type TMapFilters = {
  date_range?: string
  solution_id?: string
  state?: string
}

export type ImpactData = {
  beneficiaries?: number
  childrenBeneficiaries?: number
  childrenParticipants?: number
  newConnections?: number
  participants?: number
}

export type LocationMapApplication = {
  find(arg0: (point: any) => boolean): unknown
  geometry: any
  push(current: LocationMapApplication): unknown
  applicantName: string
  applicantPhotoUrl: string
  applicationType: 'solution_application' | 'project_application'
  createdAt: string
  fundingCents: number
  fundingCurrency: CurrencyIso
  headerImageUrl: string | null
  id: string
  latitude: string
  locationSlug: string
  longitude: string
  name: string
  solutionId: number | string
  state: TFundApplicationStatus
  submittedAt: string
  type: string
  impactData: ImpactData
}

export type LocationPointsForCluster = {
  type: string
  properties: {
    cluster: boolean
    application: LocationMapApplication
  }
  geometry: {
    type: string
    coordinates: number[]
  }
}

export type PhotosData = {
  createdAt: string
  id: string
  locationAddress: string
  locationName: string
  locationSlug: string
  photoUrl: string
  postUrl: string
  solutionId: number
  state: string
  type: string
  isFavourite: boolean
}

export type Image = {
  src: string
  alt: string
}

export enum TAccountType {
  manual = 'manual',
  stripe = 'stripe',
  wise = 'wise',
  unknown = 'unknown',
}

export enum TPaymentStatus {
  succeeded = 'succeeded',
  incomplete = 'incomplete',
  failed = 'failed',
  processing = 'processing',
  authorising = 'authorising',
}

export enum TPaymentMethod {
  stripe = 'stripe',
  brazil_business = 'brazil_business',
  iban = 'iban',
  iban_uah = 'iban_uah',
  swift_eur = 'swift_eur',
  southafrica = 'southafrica',
}

export type TPayment = {
  id: string
  type: 'payments'
  accountType: TAccountType
  allocationType: TAllocationType
  amount: number
  createdAt: string
  currency: CurrencyIso
  paymentMethod: TPaymentMethod
  reference: string
  status: TPaymentStatus
}

export type TPaymentWithRelationships = TPayment & {
  location: Location
  user: User
  solution: Solution
  approvedBy?: User
}

export type TDraftOpenGrantApplication = {
  id: string
  type: 'draft_project_applications'
  locationName: string
  name: string
  owner: Partial<User>
  requestedBudget: number
}
