import { Pagination, Text } from '@changex/design-system'
import Layout from 'app/funds/[id]/layout'
import { ApplicationsTabs } from 'features/funds/components/applications-tabs'
import { useApplicationsQueryParams } from '@features/funds/hooks/use-applications-query-params'
import {
  DRAFT_APPLICATIONS_PAGE_SIZE,
  DRAFT_APPLICATIONS_SORT_CRITERIA,
} from '@features/funds/constants'
import { useCallback } from 'react'
import { useFund } from 'shared/providers'
import { sum } from 'lodash'
import { ApplicationsCount } from 'features/funds/components/applications-count'
import { useOpenGrantDraftApplicationList } from '@features/funds/hooks/use-open-grant-draft-application-list'
import ApplicationsActions from 'features/funds/components/applications-actions'
import ApplicationsHeader from '@features/funds/components/applications-header'
import DraftApplicationsList from '@features/funds/components/draft-applications-list'

const HELP_CENTER_ARTICLE_URL =
  'https://help.changex.org/en/articles/10063281-draft-open-grant-applications'

const FundApplicationsContent = () => {
  const [applicationsQueryParams, setApplicationsQueryParams] =
    useApplicationsQueryParams()
  const fund = useFund()
  const totalApplications = fund.aggregates
    ? sum(Object.values(fund?.aggregates))
    : 0
  const draftApplications = useOpenGrantDraftApplicationList(
    fund.id,
    applicationsQueryParams.q,
    applicationsQueryParams.page,
    applicationsQueryParams.sort
  )

  const resultCount = draftApplications.data?.meta?.stats?.total?.count || 0

  // TODO: improve type (needs to be updated in the design system)
  const handleFilter = useCallback(
    (filter: any) => {
      setApplicationsQueryParams({
        page: filter.page,
      })
    },
    [setApplicationsQueryParams]
  )

  if (
    !draftApplications.isLoading &&
    !draftApplications.isFetching &&
    totalApplications === 0
  ) {
    return (
      <div className="max-w-changex mx-auto my-20 text-center">
        <Text size="2xl">No applicants yet</Text>
        <p className="mt-4">Come back soon to see new applications.</p>
      </div>
    )
  }

  return (
    <div>
      <ApplicationsHeader totalApplications={totalApplications} />
      <ApplicationsTabs fund={fund} />
      <ApplicationsActions sortCriteria={DRAFT_APPLICATIONS_SORT_CRITERIA} />
      <div className="max-w-changex mx-auto">
        <p className="mb-4 mt-2">
          Drafts are grant applications that have been started but not yet
          submitted, limited information is shown due to the application still
          being in progress. Read more about drafts{' '}
          <a href={HELP_CENTER_ARTICLE_URL}>here</a>.
        </p>
        <ApplicationsCount
          count={draftApplications.data?.meta?.stats?.total?.count || 0}
        />
        <DraftApplicationsList applicationsQuery={draftApplications} />
      </div>
      {resultCount > DRAFT_APPLICATIONS_PAGE_SIZE ? (
        <div className="mt-4">
          <Pagination
            onFiltering={handleFilter}
            currentPage={applicationsQueryParams.page ?? 1}
            ariaLabel="Applications paging"
            meta={draftApplications.data?.meta}
            resultsPerPage={DRAFT_APPLICATIONS_PAGE_SIZE}
          />
        </div>
      ) : null}
    </div>
  )
}

export default function FundApplicationsPage() {
  return (
    <Layout>
      <FundApplicationsContent />
    </Layout>
  )
}
